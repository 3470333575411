<template>
  <div class="content_history">
    <clone-tab :bgcShow="bgcShow"></clone-tab>

    <!-- 列表 -->
    <div class="history_box">
      <div v-for="(item,index) in dataList" :key="index">
        <!-- 成功 -->
        <div class="list" v-if="item.audit_state==2">
          <div class="lader_text">合约续签</div>
          <div class="expenditure_time">续费周期：{{item.union_shareholder_expire_at.split(" ")[0]}}--{{item.union_shareholder_renew_at.split(" ")[0]}}</div>
          <div style="display:flex">
            <div style="color:#A09E9E">申请时间：{{item.create_time}}</div>
            <div class="lookbtn" @click="details(item.audit_id)">查看详情</div>
          </div>
        </div>
        <!-- 成功 -->
        <div class="list2" v-else-if="item.audit_state==1||item.audit_state==0">
          <div class="lader_text">合约续签</div>
          <div class="expenditure_time">续费周期：{{item.union_shareholder_expire_at.split(" ")[0]}}--{{item.union_shareholder_renew_at.split(" ")[0]}}</div>
          <div style="display:flex">
            <div style="color:#A09E9E">申请时间：{{item.create_time}}</div>
            <div class="lookbtn" @click="details(item.audit_id)">查看详情</div>
          </div>
        </div>
        <!-- 失败 -->
        <div class="list1" v-else>
          <div class="lader_text" style="color:#757575">合约续签</div>
          <div class="expenditure_time" style="color:#757575">续费周期：{{item.union_shareholder_expire_at.split(" ")[0]}}--{{item.union_shareholder_renew_at.split(" ")[0]}}</div>
          <div style="display:flex">
            <div style="color:#757575">申请时间：{{item.create_time}}</div>
            <div class="lookbtn" style="background:#757575" @click="details(item.audit_id)">查看详情</div>
          </div>
        </div>
      </div>
     
    </div>
     <div class="nullBox" v-if="dataList.length==0">
        <img src="../../assets/boxNull.png"/>
        <div>暂无数据</div>
      </div>
  </div>
</template>
<script>
import cloneTab from "../../components/expenditure/cloneTab";
import { shareList_api } from "@/api/deal";
export default {
  data() {
    return {
      bgcShow: true,
      dataList:null
    };
  },
  created() {
    document.title = "历史记录";
    this.getList();
  },
  methods: {
    getList() {
      shareList_api().then(res => {
        console.log(res);
        if (res.code == 0) {
          this.dataList = res.data;
        }
      });
    },
    details(id){
      this.$router.push(`./histroyDetail?id=${id}`)
    }
  },
  components: {
    cloneTab
  }
};
</script>
<style>
.content_history {
  padding-top: 40px;
}
.nullBox{
  width:200px;
  margin: 400px auto 0;
  font-size: 30px;text-align: center;
}
.nullBox img{
  width: 100%
}
.list {
  width: 715px;
  height: 200px;
  background-image: url(../../assets/Slice28.png);
  background-size: 100% 100%;
  font-size: 26px;
}
.list1 {
  width: 715px;
  height: 200px;
  background-image: url(../../assets/Slice29.png);
  background-size: 100% 100%;
  font-size: 26px;
}
.list2 {
  width: 715px;
  height: 200px;
  background-image: url(../../assets/Slice50.png);
  background-size: 100% 100%;
  font-size: 26px;
}
.list,
.list1,.list2 {
  margin: 10px auto 20px;
  padding: 20px 25px;
  box-sizing: border-box;
}
.history_box {
  margin-top: 30px;
}
.lader_text {
  font-size: 35px;
  color: #fff;
}
.expenditure_time {
  color: #edbc6b;
  margin: 15px 0;
}

.lookbtn {
  width: 163px;
  height: 60px;
  background: #f1c67a;
  border-radius: 30px 30px 30px 30px;
  opacity: 1;
  font-size: 26px;
  color: #fff;
  text-align: center;
  line-height: 60px;
  margin-left: 55px;
}
</style>